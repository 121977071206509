import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111517;

  h2 {
    font-family: 'Exo 2', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    color: cyan;
  }
`;

function App() {
  return (
    <Container>
      <h2>Coming Soon</h2>
    </Container>
  );
}

export default App;
